// Define the HTML template for the ESelectOptions component
const templateHTML = `
<div class="eselect-options-container mb-3">
  <label class="select-label text-capitalize"></label>
  <select class="select-dropdown form-select" aria-label="Default select example"></select>
  <span class="hint-field form-text"></span>
  <span class="error-message"></span>
</div>
`;

// Create a template element to store the select structure
const template = document.createElement("template");
template.id = "e-select-options-template";
template.innerHTML = templateHTML;

export default class ESelectOptions extends HTMLElement {
  static templateLoaded = false;
  static template = null;

  constructor() {
    super();
    // Initialize class properties
    this.selectElement = null;
    this.hintElement = null;
    this.labelElement = null;
    this.errorMessage = null;
  }

  connectedCallback() {
    // Load the template if it's not already loaded
    if (!ESelectOptions.templateLoaded) {
      ESelectOptions.template = template;
      ESelectOptions.templateLoaded = true;
    }

    this._loadTemplate();
    this._setAttributes();
    this._applyValidation();
    this._setupChangeListener();
  }

  _loadTemplate() {
    const template = ESelectOptions.template;

    if (template) {
      const templateContent = template.content.cloneNode(true);
      this.appendChild(templateContent);

      // Initialize the element references after appending the template
      this.selectElement = this.querySelector(".select-dropdown");
      this.labelElement = this.querySelector(".select-label");
      this.hintElement = this.querySelector(".hint-field");
      this.errorMessage = this.querySelector(".error-message");
    } else {
      console.error("Template not found!");
    }
  }

  _setAttributes() {
    // Set the label text
    if(this.hasAttribute("label")){
      this.labelElement.textContent = this.getAttribute("label").replaceAll("_", " ");
    }

    this.hintElement.textContent = this.getAttribute("hint") || "";
    const vlaue = this.getAttribute("value") || "";

    // Set the multiple selection mode if the attribute is present
    if (this.hasAttribute("multiple")) {
      this.selectElement.setAttribute("multiple", "multiple");
    } else {
      this.selectElement.removeAttribute("multiple");
    }

    // Populate the select options
    const optionsAttr = this.getAttribute("options");
    if (optionsAttr) {
      try {
        const options = JSON.parse(optionsAttr);

        const optionElement = document.createElement("option");
        optionElement.value = ""; // Use value if present, otherwise name

        optionElement.textContent = "Selection"; // Use name if present, otherwise value
        this.selectElement.appendChild(optionElement);

        const hasSelected = this.getAttribute("value") || "";

        Object.keys(options).forEach((index) => {
          const option = options[index];

          const optionElement = document.createElement("option");
          optionElement.value = index; // Use value if present, otherwise name
          if (hasSelected.includes(index)) {
            optionElement.selected = true;
          }

          if (vlaue === index) {
            optionElement.selected = true; // Use value if present, otherwise name
          }
          optionElement.textContent = option; // Use name if present, otherwise value
          this.selectElement.appendChild(optionElement);
        });
      } catch (error) {
        console.error(
          "Invalid options format. Expected JSON array of objects with 'name' and 'value' properties."
        );
      }
    } else {
      console.error("Attribute 'options' is missing or invalid.");
    }

    // Set other attributes if needed
    if (this.hasAttribute("required")) {
      this.selectElement.required = true;
    }

    // Set name attribute for the select element
    if (this.hasAttribute("name")) {
      this.selectElement.name = this.getAttribute("name");
    }
  }

  _applyValidation() {
    if (this.hasAttribute("required")) {
      this.selectElement.addEventListener("change", () => {
        if (
          !this.selectElement.value &&
          !this.selectElement.hasAttribute("multiple")
        ) {
          this.errorMessage.textContent = "Please select an option.";
        } else if (
          this.selectElement.hasAttribute("multiple") &&
          !this.selectElement.selectedOptions.length
        ) {
          this.errorMessage.textContent = "Please select at least one option.";
        } else {
          this.errorMessage.textContent = "";
        }
      });
    }
  }

  _setupChangeListener() {
    // Update selected values whenever the selection changes
    this.selectElement.addEventListener("change", () => {
      this.dispatchEvent(
        new CustomEvent("selection-changed", {
          detail: { selectedValues: this.getSelectedValues() },
          bubbles: true,
        })
      );
    });
  }

  getSelectedValues() {
    if (this.selectElement.hasAttribute("multiple")) {
      return Array.from(this.selectElement.selectedOptions).map(
        (option, index) => index
      );
    } else {
      return this.selectElement.value;
    }
  }
}
