// Define the HTML template for the EInput component
const templateHTML = `
<div class="einput-container position-relative mb-3">
  <label class="form-label input-label"></label>
  <div class="input-wrapper">
    <input class="input-field form-control" />
    <textarea class="textarea-field form-control"></textarea>
  </div>
  <span class="hint-field form-text"></span>
  <span class="error-message"></span>
</div>
`;

// Create a template element to store the input structure
const template = document.createElement("template");
template.id = "e-input-template";
template.innerHTML = templateHTML;

export default class EInput extends HTMLElement {
  static templateLoaded = false;
  static template = null;

  constructor() {
    super();
    // Initialize class properties
    this.inputField = null;
    this.textareaField = null;
    this.errorMessage = null;
    this.hintElement = null;
    this.labelElement = null;
    this.togglePasswordButton = null;
    this.patterns = [];
  }

  connectedCallback() {
    // Load the template if it's not already loaded
    if (!EInput.templateLoaded) {
      EInput.template = template;
      EInput.templateLoaded = true;
    }

    this._loadTemplate();
    this._setAttributes();
    this._applyFilters();
    this._addEventListeners();
  }

  _loadTemplate() {
    const template = EInput.template;

    if (template) {
      // Clone the template content and attach it to the custom element
      const templateContent = template.content.cloneNode(true);
      this.appendChild(templateContent);

      // Store references to key elements within the template
      this.inputField = this.querySelector(".input-field");
      this.textareaField = this.querySelector(".textarea-field");
      this.errorMessage = this.querySelector(".error-message");
      this.hintElement = this.querySelector(".hint-field");
      this.labelElement = this.querySelector(".input-label");
    } else {
      console.error("Template not found!"); // Error handling if the template is missing
    }
  }

  _setAttributes() {
    // Set the input type and determine if it's a textarea
    const type = this.getAttribute("type") || "text";
    const currentValue = this.getAttribute("value") || "";
    if (this.hasAttribute("class")) {
      this.inputField.classList.add(...this.getAttribute("class").split(" "));
    }

    if (type === "textarea") {
      this.inputField.remove();

      // Set attributes for textarea
      const rows = this.getAttribute("rows") || 4;
      const cols = this.getAttribute("cols") || 50;
      this.textareaField.rows = rows;
      this.textareaField.cols = cols;
      this.textareaField.id =
        this.getAttribute("id") || `e-input-${this._generateUniqueId()}`;
      this.textareaField.name = this.getAttribute("name") || "";
      this.textareaField.textContent = currentValue;
      if (this.hasAttribute("required")) {
        this.textareaField.required = true;
      }
    } else {
      this.textareaField.remove();
      // Show input and hide textarea
      this.inputField.type = type;

      // Set attributes for input
      this.inputField.id =
        this.getAttribute("id") || `e-input-${this._generateUniqueId()}`;
      this.inputField.name = this.getAttribute("name") || "";
      this.inputField.value = currentValue;
      if (this.hasAttribute("required")) {
        this.inputField.required = true;
      }

      // Add password toggle button if type is password
      if (type === "password") {
        const eye = document.createElement("span");
        eye.textContent = "👁️";
        eye.style.cursor = "pointer";
        eye.style.position = "absolute";
        eye.style.right = "8px";
        eye.style.top = "38px";
        eye.style.display = "inline";
        this.children[0].appendChild(eye);
        this.togglePasswordButton = eye;
      }
    }

    // Set Help Inline attributes for hint
    const hint = this.getAttribute("hint") || "";
    this.hintElement.textContent = hint;

    // Set common attributes for label
    if (this.hasAttribute("label")) {
      const label = this.getAttribute("label") || "";
      this.labelElement.textContent = label;
    } else {
      this.labelElement.remove();
    }

    // Set placeholder if available
    const placeholder = this.getAttribute("placeholder") || "";
    if (type === "textarea") {
      this.textareaField.placeholder = placeholder;
    } else {
      this.inputField.placeholder = placeholder;
    }
  }

  _applyFilters() {
    const type = this.getAttribute("type") || "text";

    // Apply filters to input fields
    if (type !== "textarea") {
      // Apply min and max filters for number types
      if (this.inputField.type === "number") {
        const min = this.getAttribute("min");
        const max = this.getAttribute("max");
        if (min !== null) this.inputField.min = min;
        if (max !== null) this.inputField.max = max;
      }

      // Apply pattern filters based on attributes
      if (this.hasAttribute("only-lowercase")) {
        this.patterns.push("^[a-z]+$");
      }

      if (this.hasAttribute("lowercase")) {
        this.patterns.push("^[a-z ]+$");
      }

      if (this.hasAttribute("email")) {
        this.patterns.push("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$");
      }

      if (this.hasAttribute("password")) {
        this.patterns.push(
          "^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*[!@#$.%^&*])(?=.{8,32})"
        );
      }

      if (this.hasAttribute("without-special")) {
        this.patterns.push("^[a-zA-Z0-9]+$");
      }

      if (this.hasAttribute("includes")) {
        const includeChars = this.getAttribute("includes");
        this.patterns.push(`^.*[${includeChars}].*$`);
      }

      if (this.hasAttribute("number")) {
        this.patterns.push("^\\d+$");
      }

      if (this.hasAttribute("notnumber")) {
        this.patterns.push("^[^\\d]+$");
      }
    }
  }

  _addEventListeners() {
    const type = this.getAttribute("type") || "text";

    if (type === "textarea") {
      // Add an event listener for textarea validation
      this.textareaField.addEventListener("input", () => {
        this._validateTextarea();
      });
    } else {
      // Add an event listener for input validation
      this.inputField.addEventListener("input", () => {
        this._validateInput();
      });

      // Add event listener for toggling password visibility
      if (type === "password" && this.togglePasswordButton) {
        this.togglePasswordButton.addEventListener("click", () => {
          this._togglePasswordVisibility();
        });
      }
    }
  }

  _validateInput() {
    const value = this.inputField.value;

    let valid = true;

    // Loop through all patterns and validate the input
    this.patterns.forEach((pattern) => {
      if (pattern) {
        const regex = new RegExp(pattern);

        if (!regex.test(value) && value.length > 0) {
          valid = false;
        }
      }
    });

    if (!valid) {
      this._showError("Invalid input format.");
      return;
    }

    // Additional validation for number type
    if (this.inputField.type === "number") {
      const min = this.inputField.min;
      const max = this.inputField.max;
      if (min && value.length > 0 && value < min) {
        this._showError(`Value must be greater than or equal to ${min}.`);
        return;
      }
      if (max && value.length > 0 && value > max) {
        this._showError(`Value must be less than or equal to ${max}.`);
        return;
      }
    }

    this._clearError();
  }

  _validateTextarea() {
    const value = this.textareaField.value;
    const minLength = this.getAttribute("minlength");
    const maxLength = this.getAttribute("maxlength");

    // Validate the length of the textarea input
    if (minLength && value.length > 0 && value.length < minLength) {
      this._showError(`Text must be at least ${minLength} characters long.`);
      return;
    }

    if (maxLength && value.length > maxLength) {
      this._showError(
        `Text must be no more than ${maxLength} characters long.`
      );
      return;
    }

    this._clearError();
  }

  _showError(message) {
    // Display error message
    this.errorMessage.textContent = message;
    this.errorMessage.classList.add("invalid-feedback");
    this.errorMessage.style.display = "block";
  }

  _clearError() {
    // Clear any existing error messages
    this.errorMessage.textContent = "";
    this.errorMessage.style.display = "none";
  }

  _togglePasswordVisibility() {
    // Toggle the visibility of the password input
    if (this.inputField.type === "password") {
      this.inputField.type = "text";
      this.togglePasswordButton.textContent = "🔒";
    } else {
      this.inputField.type = "password";
      this.togglePasswordButton.textContent = "👁️";
    }
  }

  _generateUniqueId() {
    // Helper method to generate a unique identifier
    return Math.floor(Math.random() * 100000);
  }
}
