import EInput from "./input.js";
import EInputOtions from "./input-options.js";
import ERangeInput from "./input-range.js";
import ESelectOptions from "./select-options.js";
import ECheckboxOptions from "./checkbox-options";

// Define the custom element
customElements.define("e-input", EInput);
customElements.define("e-input-options", EInputOtions);
customElements.define("e-input-range", ERangeInput);
customElements.define("e-select-options", ESelectOptions);
customElements.define("e-checkbox-options", ECheckboxOptions);
