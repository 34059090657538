// Define the HTML template for the EInputOptions component
const templateHTML = `
<div class="einputotions-container">
  <label class="input-label"></label>
  <div class="option-list"></div>
  <span class="hint-field form-text"></span>
  <span class="error-message"></span>
</div>
`;

// Create a template element to store the input structure
const template = document.createElement("template");
template.id = "e-input-options-template";
template.innerHTML = templateHTML;

export default class EInputOptions extends HTMLElement {
  static templateLoaded = false;
  static template = null;

  constructor() {
    super();
    // Initialize class properties
    this.optionList = null;
    this.hintElement = null;
    this.labelElement = null;
    this.errorMessage = null;
  }

  connectedCallback() {
    // Load the template if it's not already loaded
    if (!EInputOptions.templateLoaded) {
      EInputOptions.template = template;
      EInputOptions.templateLoaded = true;
    }

    this._loadTemplate();
    this._setAttributes();
    this._applyValidation();
  }

  _loadTemplate() {
    const template = EInputOptions.template;

    if (template) {
      const templateContent = template.content.cloneNode(true);
      this.appendChild(templateContent);

      // Initialize the element references after appending the template
      this.optionList = this.querySelector(".option-list");
      this.labelElement = this.querySelector(".input-label");
      this.hintElement = this.querySelector(".hint-field");
      this.errorMessage = this.querySelector(".error-message");
    } else {
      console.error("Template not found!");
    }
  }

  _setAttributes() {
    // Set the label text
    this.labelElement.textContent = this.getAttribute("label") || "";
    this.hintElement.textContent = this.getAttribute("hint") || "";

    let type, id, className;

    if (this.hasAttribute("checkbox-options")) {
      type = "checkbox";
      id = "checkbox";
      className = "checkbox-wrapper";
    }
    if (this.hasAttribute("radio-options")) {
      type = "radio";
      id = "radio";
      className = "radio-wrapper";
    }

    // Ensure that the options attribute exists and is valid
    const optionsAttr = this.getAttribute(type + "-options");
    if (optionsAttr) {
      const options = optionsAttr.split(",").map((opt) => opt.trim());

      options.forEach((option, index) => {
        const wrapper = document.createElement("div");
        wrapper.classList.add(className, "form-check");

        const el = document.createElement("input");
        el.type = type;
        el.id = `${id}-${index}`;
        el.value = option;
        el.name = this.getAttribute("name") || id;
        el.classList.add("form-check-input");
        wrapper.appendChild(el);

        const label = document.createElement("label");
        label.htmlFor = el.id;
        label.textContent = option;
        label.classList.add("form-check-label");
        wrapper.appendChild(label);

        this.optionList.appendChild(wrapper);
      });
    } else {
      console.error(`Attribute ${type}-options is missing or invalid.`);
    }
  }

  _applyValidation() {
    // Handle checkbox validation
    if (this.hasAttribute("max-checked") || this.hasAttribute("min-checked")) {
      const maxChecked =
        parseInt(this.getAttribute("max-checked"), 10) || Infinity;
      const minChecked = parseInt(this.getAttribute("min-checked"), 10) || 0;

      this.optionList.addEventListener("change", () => {
        const checkedBoxes = this.optionList.querySelectorAll(
          'input[type="checkbox"]:checked'
        );

        if (checkedBoxes.length > maxChecked) {
          this.errorMessage.textContent = `You can select a maximum of ${maxChecked} options.`;
          this._disableUncheckedCheckboxes();
        } else if (checkedBoxes.length < minChecked) {
          this.errorMessage.textContent = `Please select at least ${minChecked} options.`;
          this._enableCheckboxes();
        } else {
          this.errorMessage.textContent = "";
          this._enableCheckboxes();
        }
      });
    }

    // Handle radio button validation (if required)
    if (this.hasAttribute("required") && this.optionList) {
      this.optionList.addEventListener("change", () => {
        const selectedRadio = this.optionList.querySelector(
          'input[type="radio"]:checked'
        );
        if (!selectedRadio) {
          this.errorMessage.textContent = "Please select an option.";
        } else {
          this.errorMessage.textContent = "";
        }
      });
    }
  }

  _disableUncheckedCheckboxes() {
    const uncheckedCheckboxes = this.optionList.querySelectorAll(
      'input[type="checkbox"]:not(:checked)'
    );
    uncheckedCheckboxes.forEach((checkbox) => {
      checkbox.disabled = true;
    });
  }

  _enableCheckboxes() {
    const checkboxes = this.optionList.querySelectorAll(
      'input[type="checkbox"]'
    );
    checkboxes.forEach((checkbox) => {
      checkbox.disabled = false;
    });
  }
}
