// Define the HTML template for the ECheckboxOptions component
const templateHTML = `
<div class="e-checkbox-options-container mb-3">
  <label class="checkbox-label text-capitalize"></label>
  <div class="checkbox-group"></div>
  <span class="hint-field form-text"></span>
  <span class="error-message"></span>
</div>
`;

// Create a template element to store the checkbox structure
const template = document.createElement("template");
template.id = "e-checkbox-options-template";
template.innerHTML = templateHTML;

export default class ECheckboxOptions extends HTMLElement {
  static templateLoaded = false;
  static template = null;

  constructor() {
    super();
    // Initialize class properties
    this.checkboxGroup = null;
    this.hintElement = null;
    this.labelElement = null;
    this.errorMessage = null;
  }

  connectedCallback() {
    // Load the template if it's not already loaded
    if (!ECheckboxOptions.templateLoaded) {
      ECheckboxOptions.template = template;
      ECheckboxOptions.templateLoaded = true;
    }

    this._loadTemplate();
    this._setAttributes();
    this._applyValidation();
    this._setupChangeListener();
  }

  _loadTemplate() {
    const template = ECheckboxOptions.template;

    if (template) {
      const templateContent = template.content.cloneNode(true);
      this.appendChild(templateContent);

      // Initialize the element references after appending the template
      this.checkboxGroup = this.querySelector(".checkbox-group");
      this.labelElement = this.querySelector(".checkbox-label");
      this.hintElement = this.querySelector(".hint-field");
      this.errorMessage = this.querySelector(".error-message");
    } else {
      console.error("Template not found!");
    }
  }

  _setAttributes() {
    // Set the label text
    if (this.hasAttribute("label")) {
      this.labelElement.textContent = this.getAttribute("label").replaceAll(
        "_",
        " "
      );
    }
    this.hintElement.textContent = this.getAttribute("hint") || "";

    // Populate the checkbox options
    const optionsAttr = this.getAttribute("options");
    const selectedValues = this.getAttribute("value") || "";

    if (optionsAttr) {
      try {
        const options = JSON.parse(optionsAttr);

        Object.keys(options).forEach((index) => {
          const option = options[index];

          // Create checkbox element
          const checkboxContainer = document.createElement("div");
          checkboxContainer.className = "form-check";

          const checkbox = document.createElement("input");
          checkbox.type = "checkbox";
          checkbox.value = index; // This is the value submitted
          checkbox.id = `checkbox-${index}`;
          checkbox.name = this.getAttribute("name"); // Set the same name for all checkboxes
          checkbox.className = "form-check-input";
          checkbox.checked = selectedValues.includes(index);

          const label = document.createElement("label");
          label.htmlFor = checkbox.id;
          label.className = "form-check-label";
          label.textContent = option;

          // Append checkbox and label to container
          checkboxContainer.appendChild(checkbox);
          checkboxContainer.appendChild(label);
          this.checkboxGroup.appendChild(checkboxContainer);
        });
      } catch (error) {
        console.error(
          "Invalid options format. Expected JSON array of objects with 'name' and 'value' properties."
        );
      }
    } else {
      console.error("Attribute 'options' is missing or invalid.");
    }

    // Set required attribute if needed
    if (this.hasAttribute("required")) {
      this.checkboxGroup.required = true;
    }

    // Set name attribute for checkboxes
    if (this.hasAttribute("name")) {
      this.checkboxGroup.setAttribute("name", this.getAttribute("name"));
    }
  }

  _applyValidation() {
    if (this.hasAttribute("required")) {
      const checkboxes = this.checkboxGroup.querySelectorAll(
        "input[type='checkbox']"
      );
      checkboxes.forEach((checkbox) => {
        checkbox.addEventListener("change", () => {
          const isChecked = Array.from(checkboxes).some((cb) => cb.checked);
          this.errorMessage.textContent = isChecked
            ? ""
            : "Please select at least one option.";
        });
      });
    }
  }

  _setupChangeListener() {
    // Update selected values whenever the selection changes
    this.checkboxGroup.addEventListener("change", () => {
      this.dispatchEvent(
        new CustomEvent("selection-changed", {
          detail: { selectedValues: this.getSelectedValues() },
          bubbles: true,
        })
      );
    });
  }

  getSelectedValues() {
    const checkboxes = this.checkboxGroup.querySelectorAll(
      "input[type='checkbox']"
    );
    return Array.from(checkboxes)
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value);
  }
}
