// Define the HTML template for the ERangeInput component
const templateHTML = `
<div class="erangeinput-container">
  <label class="input-label"></label>
  <input type="range" class="range-input form-range">
  <output class="range-output form-text"></output>
  <span class="hint-field form-text"></span>
  <span class="error-message"></span>
</div>
`;

// Create a template element to store the input structure
const template = document.createElement("template");
template.id = "e-range-input-template";
template.innerHTML = templateHTML;

export default class ERangeInput extends HTMLElement {
  static templateLoaded = false;
  static template = null;

  constructor() {
    super();
    // Initialize class properties
    this.rangeInput = null;
    this.labelElement = null;
    this.hintElement = null;
    this.errorMessage = null;
    this.outputElement = null;
  }

  connectedCallback() {
    // Load the template if it's not already loaded
    if (!ERangeInput.templateLoaded) {
      ERangeInput.template = template;
      ERangeInput.templateLoaded = true;
    }

    this._loadTemplate();
    this._setAttributes();
    this._applyValidation();
  }

  _loadTemplate() {
    const template = ERangeInput.template;

    if (template) {
      const templateContent = template.content.cloneNode(true);
      this.appendChild(templateContent);

      // Initialize the element references after appending the template
      this.rangeInput = this.querySelector(".range-input");
      this.labelElement = this.querySelector(".input-label");
      this.hintElement = this.querySelector(".hint-field");
      this.errorMessage = this.querySelector(".error-message");
      this.outputElement = this.querySelector(".range-output");
    } else {
      console.error("Template not found!");
    }
  }

  _setAttributes() {
    // Set the label text
    this.labelElement.textContent = this.getAttribute("label") || "";
    this.hintElement.textContent = this.getAttribute("hint") || "";

    // Set attributes for the range input
    this.rangeInput.min = this.getAttribute("min") || "0";
    this.rangeInput.max = this.getAttribute("max") || "100";
    this.rangeInput.step = this.getAttribute("step") || "1";
    this.rangeInput.name = this.getAttribute("name") || "range";
    this.rangeInput.value =
      this.getAttribute("value") ||
      (
        this.rangeInput.min +
        (this.rangeInput.max - this.rangeInput.min) / 2
      ).toString();

    // Set initial output value
    this.outputElement.textContent = this.rangeInput.value;

    // Update output value dynamically
    this.rangeInput.addEventListener("input", () => {
      this.outputElement.textContent = this.rangeInput.value;
    });
  }

  _applyValidation() {
    const min = parseFloat(this.rangeInput.min);
    const max = parseFloat(this.rangeInput.max);
    const value = parseFloat(this.rangeInput.value);

    this.rangeInput.addEventListener("input", () => {
      const currentValue = parseFloat(this.rangeInput.value);

      if (currentValue < min || currentValue > max) {
        this.errorMessage.textContent = `Value must be between ${min} and ${max}.`;
      } else {
        this.errorMessage.textContent = "";
      }
    });
  }
}
